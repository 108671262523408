import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_Social"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://declaraidp.b2clogin.com/tfp/4416a5ab-54c5-49a3-92e5-fd30c3d76352/b2c_1_social",
            //authority: "https://login.cloud.declara.org/tfp/4416a5ab-54c5-49a3-92e5-fd30c3d76352/b2c_1_social",
            //authority: "https://login.cloud.declara.org/4416a5ab-54c5-49a3-92e5-fd30c3d76352/B2C_1_Social",
        }
    },
    authorityDomain: "declaraidp.b2clogin.com"
    //authorityDomain: "login.cloud.declara.org"
}

export const msalConfig = {
    auth: {
        clientId: "06a537af-2cd9-45de-814b-12c646d059ca", // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Use a sign-up/sign-in user-flow as a default authority
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                    //    console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                    //    console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;		
                }	
            }	
        }	
    }
};

export const protectedResources = {
    streamApi: {
        endpoint: "https://localhost:7000/",
        //scopes: ["https://idp.cloud.declara.org/api/Stream.View"], // e.g. api://xxxxxx/access_as_user
    },
};

export const loginRequest = {
    //scopes: [...protectedResources.streamApi.scopes]
};

export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
  };