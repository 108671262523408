import React from 'react';
import videojs from 'video.js';
import { ApiRequest } from './App';
import 'video.js/dist/video-js.css';

class VideoPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.placeholderRef = React.createRef();
        this.playerRef = React.createRef();
        const { options, onReady, contentId } = props;

        this.options = {
            autoplay: false,
            controls: true,
            responsive: true,
            fluid: true
        };

        this.onReady = onReady;

        this.state = {
            error: null,
            isLoaded: false,
            content: null,
            contentId: contentId
        };
    }

    componentDidMount() {
        // Make sure Video.js player is only initialized once
        if (!this.playerRef.current) {
            const placeholderEl = this.placeholderRef.current;
            const videoElement = placeholderEl.appendChild(
                document.createElement("video-js")
            );

            const player = (this.playerRef.current = videojs(videoElement, this.options, () => {
                player.log("player is ready");
                //onReady && onReady(player);
            }));

            player.addClass('vjs-big-play-centered');
        }

        else {
            const player = this.playerRef.current;
            player.autoplay(this.options.autoplay);
            player.src(this.options.sources);
        }

        ApiRequest("http://192.168.1.2:7071/api/getcontentbyid?contentId=" + this.state.contentId, "GET")
            .then((data) => {
                this.setState({
                    isLoaded: true,
                    content: data
                });

                //this.options['autoplay'] = data['isLive'];

                var player = this.playerRef.current;

                player.src({ type: data['contentType'], src: data['contentUri'] });
                player.autoplay(data['isLive']);

                player.options({ liveui: data['isLive'] });
                if (data['posterUri']) {
                    player.poster(data['posterUri']);
                }

                document.title = data['contentName'];
            })
            .catch((error) => {
                this.playerRef.current.dispose();
                console.log("Content not found");
                console.log(error)
            });
    }

    componentWillUnmount() {
        const player = this.playerRef.current;

        return () => {
            if (!player || player.isDisposed()) return
            player.dispose()
            this.playerRef.current = null
        };
    }

    render() {
        return (
            <div ref={this.placeholderRef} id='videoPlayer' className='videoPlayer'></div>
        );
    }
}

export default VideoPlayer;