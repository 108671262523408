import React, { useState, useEffect } from "react";

import { BrowserRouter as Router, Routes, Route, Link, Switch, Navigate } from "react-router-dom";

import logo from './logo.svg';
import './App.css';

import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { EventType, InteractionType, InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";

import { msalConfig, b2cPolicies, loginRequest } from "./authConfig";
import { PageLayout, IdTokenClaims } from "./ui";


import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { format, parseISO } from "date-fns";

import Watch from "./watch";
import { msalInstance } from "./index";

const IdTokenContent = () => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance, 
     * an array of all accounts currently signed in and an inProgress value 
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { accounts } = useMsal();
    const [idTokenClaims, setIdTokenClaims] = useState(null);

    function GetIdTokenClaims() {
        setIdTokenClaims(accounts[0].idTokenClaims)
    }

    return (
        <>
            <h5 className="card-title">Welcome {accounts[0].name}</h5>
            {idTokenClaims ?
                <IdTokenClaims idTokenClaims={idTokenClaims} />
                :
                <Button variant="secondary" onClick={GetIdTokenClaims}>View ID Token Claims</Button>
            }
        </>
    );
};

const Login = () => {

    const { instance, inProgress } = useMsal();
    useMsalAuthentication(InteractionType.Redirect);
    //instance.setActiveAccount(instance.getAllAccounts[0]);
    //console.log('Active account: ' + instance.getActiveAccount());
    /**
     * Using the event API, you can register an event callback that will do something when an event is emitted. 
     * When registering an event callback in a react component you will need to make sure you do 2 things.
     * 1) The callback is registered only once
     * 2) The callback is unregistered before the component unmounts.
     * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
     */
    useEffect(() => {


        const callbackId = instance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_FAILURE) {
                if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
                    if (event.interactionType === InteractionType.Redirect) {
                        instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
                    } else if (event.interactionType === InteractionType.Popup) {
                        instance.loginPopup(b2cPolicies.authorities.forgotPassword)
                            .catch(e => {
                                return;
                            });
                    }
                }
            }

            if (event.eventType === EventType.LOGIN_SUCCESS) {
                if (event?.payload) {
                    /**
                     * We need to reject id tokens that were not issued with the default sign-in policy.
                     * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
                     * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                     */
                    if (event.payload.idTokenClaims["acr"] === b2cPolicies.names.forgotPassword) {
                        window.alert("Password has been reset successfully. \nPlease sign-in with your new password\n" + event.payload.idTokenClaims["acr"]);
                        return instance.logout();
                    }
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };

    }, []);
};

class ContentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: []
        };
    }

    componentDidMount() {
        ApiRequest('http://192.168.1.2:7071/api/getcontent', 'GET')
            .then((data) => {
                this.setState({
                    isLoaded: true,
                    items: data
                });
            });
    }

    render() {
        const { error, isLoaded, items } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        }
        else if (!isLoaded) {
            return <div>Loading...</div>;
        }
        else {
            return (
                <div className="mt-5">
                    {items.filter(f => f.isFeatured).length > 0 ?
                        <Row className="mb-5">
                            <Col>
                                <Carousel>
                                    {items.filter(f => f.isFeatured).map(item => (
                                        <Carousel.Item>
                                            <a href={"/watch/" + item.contentId + "/" + item.contentName.replaceAll(" ", "-")} className="stretched-link text-reset text-decoration-none">
                                                <img className="d-block w-100" src={item.posterUri} alt={item.contentName} />
                                            </a>
                                            <Carousel.Caption style={{ backgroundColor: "rgba(0,0,0,0.75" }}>
                                                <h3>{item.contentName}</h3>
                                                <p>{format(parseISO(item.originalAirDate), "MMMM do, yyyy h:mm aaa")}</p>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </Col>
                        </Row>
                        : null}
                    <Row xs={1} md={2} lg={3} xxl={4} className="g-4">
                        {
                            items.filter(f => !f.isFeatured).map(item => (
                                <Col key={item.contentId}>
                                    <Card style={{ width: '300px' }} className="mx-auto" text="dark">
                                        <Card.Img variant="top" src={item.posterUri} />
                                        <Card.Body>
                                            <Card.Title><a href={"/watch/" + item.contentId + "/" + item.contentName.replaceAll(" ", "-")} className="stretched-link text-reset text-decoration-none">{item.contentName}</a></Card.Title>
                                            {item.isLive ? <Card.ImgOverlay className="text-start"><Card.Subtitle className="text-danger"><span style={{ backgroundColor: "#000" }}>Live</span></Card.Subtitle></Card.ImgOverlay> : null}
                                            <Card.Text>{format(parseISO(item.originalAirDate), "MMMM do, yyyy h:mm aaa")}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            );
        }
    }
}

const MainContent = () => {
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <ContentList />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in.</h5>
            </UnauthenticatedTemplate>
        </div>
    );
};

export function ApiRequest(uri, method) {
    const instance = msalInstance;
    const accounts = instance.getAllAccounts();

    const tokenRequest = {
        account: accounts[0],
        scopes: loginRequest.scopes,
    };

    return new Promise((resolve, reject) => {
        instance.acquireTokenSilent(tokenRequest).then(
            tokenResponse => {
                var headers = new Headers();
                var bearer = "Bearer " + tokenResponse.accessToken;
                headers.append("Authorization", bearer);

                var options = {
                    method: method,
                    headers: headers
                };

                fetch(uri, options)
                    .then((result) => {
                        if (result.ok) {
                            resolve(result.json());
                        }
                        else if (result.status === 404) {
                            reject();
                        }
                    })
                    .catch();
            })
            .catch(async (error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    //fallback to interaction when silent call fails
                    return instance.acquireTokenPopup(loginRequest);
                    console.log("No token");
                    reject(error);
                }
            }).catch(error => {
                console.log(error);
            });
    });
}

function App({ msalInstance }) {

    return (
        <MsalProvider instance={msalInstance}>
            <AuthenticatedTemplate>
                <PageLayout>
                    <Router>
                        <Routes>
                            <Route path="/watch/:id/*" element={<Watch />} />
                            <Route path="/" element={<MainContent />} />
                        </Routes>
                    </Router>
                </PageLayout>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
        </MsalProvider>

    );
}

export default App;
