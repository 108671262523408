import React from 'react';
import { useParams } from "react-router-dom"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import VideoPlayer from "./player";

const Watch = () => {
    const { id } = useParams();

    return (
        <>
            <AuthenticatedTemplate>
                {
                    <VideoPlayer contentId={id} />
                }
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div>Not authenticated!</div>
            </UnauthenticatedTemplate>
        </>
    );
}

export default Watch;